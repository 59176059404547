// preload.js
import { getPreloadImage } from '../utils/imageHelper';
import settings from './settings'; 

// This holds Cookie Consent settings and Google Analytics settings. Uncomment if you need them.
import './ext'; 

document.addEventListener('DOMContentLoaded', function() {
    const imageConfig = getPreloadImage(settings);

    const preloadLinkImage = document.createElement('link');
    preloadLinkImage.rel = 'preload';
    preloadLinkImage.as = 'image';
    preloadLinkImage.href = imageConfig.srcWebp;
    document.head.appendChild(preloadLinkImage);

    // Preload fonts
    const fonts = [
        { href: 'fonts/Byrd-ExtraBold.woff2', type: 'font/woff2' },
        { href: 'fonts/Byrd-ExtraBold.woff', type: 'font/woff' },
        { href: 'fonts/Byrd-Light.woff2', type: 'font/woff2' },
        { href: 'fonts/Byrd-Light.woff', type: 'font/woff' }//,
        //{ href: '/fonts/Inter-VariableFont_slnt,wght.woff2', type: 'font/woff2' },
        //{ href: '/fonts/Inter-VariableFont_slnt,wght.ttf', type: 'font/ttf' }    
    ];

    fonts.forEach(font => {
        const preloadLinkFont = document.createElement('link');
        preloadLinkFont.rel = 'preload';
        preloadLinkFont.as = 'font';
        preloadLinkFont.href = font.href;
        preloadLinkFont.crossOrigin = 'anonymous';
        document.head.appendChild(preloadLinkFont);
    });
});
