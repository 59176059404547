// imageHelper.js
export function getPreloadImage(settings) {
    const width = window.innerWidth;
    let baseName = 'why/why';
    let suffix = '';
    let dimensions = { width: settings.geometry.breakpoints.xxxl, height: 630 };

    if (width < settings.geometry.breakpoints.tiny) {
        suffix = '_tiny';
        dimensions = { width: settings.geometry.breakpoints.tiny, height: 450 };
    } else if (width < settings.geometry.breakpoints.sm) {
        suffix = '_ultra_slim';
        dimensions = { width: settings.geometry.breakpoints.sm, height: 360 };
    } else if (width < settings.geometry.breakpoints.md) {
        suffix = '_slim';
        dimensions = { width: settings.geometry.breakpoints.md, height: 400 };
    }

    const roundedDpr = Math.round(window.devicePixelRatio);
    const sizeSuffix = roundedDpr > 2 ? '@3x' : roundedDpr > 1.5 ? '@2x' : roundedDpr > 1 ? '@1.5x' : '@1x';
    suffix += sizeSuffix;

    return {
        srcWebp: `${baseName}${suffix}.webp`,
        srcPng: `${baseName}${suffix}.png`,
        ...dimensions
    };
}